import React from 'react';

const BlogsPage = () => {
    return (
        <div style={{ background: 'white', height: '500px', color: 'black', margin: '10em' }}>
            Blogs will be listed here
        </div>
    );
};

export default BlogsPage;