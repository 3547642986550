import React, { useState } from 'react';
// import { CSSTransition } from "react-transition-group";

const Faq = () => {
  const [openedQuestion, setOpenedQuestion] = useState(0);

  const handleOpenAnswer = (id) => {
    setOpenedQuestion(id);
  };

  return (
    <div className='faq-container'>
      <div className='faq-title'>
        Frequently{' '}
        <span
          style={{
            fontWeight: 700,
            color: '#CC2D2D',
          }}
        >
          Asked
        </span>{' '}
        Questions
      </div>
      <div className='faq-questions-container'>
        <div className='faq-question'>
          <div
            className='faq-question-title'
            onClick={() => handleOpenAnswer(0)}
          >
            What is blockchain technology and how can it benefit my business?
          </div>
          {openedQuestion === 0 ? (
            <div className='faq-question-answer'>
              Blockchain technology is a decentralized and secure digital ledger
              that records transactions across multiple computers. It can
              benefit your business by increasing transparency, security, and
              efficiency in various industries such as finance, supply chain,
              and healthcare.
            </div>
          ) : null}
        </div>
        <hr color='#D3D3D3' />
        <div className='faq-question'>
          <div
            className='faq-question-title'
            onClick={() => handleOpenAnswer(1)}
          >
            Why should I hire a blockchain development company?
          </div>
          {openedQuestion === 1 ? (
            <div className='faq-question-answer'>
              Blockchain development companies can tailor solutions to meet your
              specific business requirements. They can analyze your needs,
              identify the appropriate blockchain technology, and design a
              solution that aligns with your objectives. Whether you need a
              private blockchain for internal processes or a decentralized
              application (DApp) for public use, a blockchain development
              company can deliver a customized solution.
            </div>
          ) : null}
        </div>
        <hr color='#D3D3D3' />
        <div className='faq-question'>
          <div
            className='faq-question-title'
            onClick={() => handleOpenAnswer(2)}
          >
            What services does your blockchain development company provide?
          </div>
          {openedQuestion === 2 ? (
            <div className='faq-question-answer'>
              A typical blockchain development company offers services such as
              blockchain consulting, smart contract development, blockchain
              application development (DApps), private blockchain development,
              tokenization and ICO development, blockchain integration,
              blockchain security and auditing, as well as blockchain
              maintenance and support.
            </div>
          ) : null}
        </div>
        <hr color='#D3D3D3' />
        <div className='faq-question'>
          <div
            className='faq-question-title'
            onClick={() => handleOpenAnswer(3)}
          >
            How long does it take to develop a blockchain solution?
          </div>
          {openedQuestion === 3 ? (
            <div className='faq-question-answer'>
              The development timeline for a blockchain solution can vary
              depending on several factors, including the complexity of the
              project, the size of the development team, the chosen blockchain
              platform, and the specific requirements of the solution.
              Generally, developing a blockchain solution involves multiple
              stages, such as planning, design, development, testing, and
              deployment.
            </div>
          ) : null}
        </div>
        <hr color='#D3D3D3' />
        <div className='faq-question'>
          <div
            className='faq-question-title'
            onClick={() => handleOpenAnswer(4)}
          >
            How much does blockchain development cost?
          </div>
          {openedQuestion === 4 ? (
            <div className='faq-question-answer'>
              The cost of blockchain development can vary significantly
              depending on various factors, including the complexity of the
              project, the scope of work, the chosen blockchain platform, the
              development team's rates, and the region where the development is
              done.
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default Faq;
